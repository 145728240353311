<template>
  <div class="d-flex flex-column py-4">
    <div class="header-top">
      <h2 class="view-title">Competitor History</h2>
      <v-text-field
        label="Search"
        v-model="search.search"
        class="search"
        prepend-icon="mdi-magnify"
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      sort-by="id"
      :sort-desc="sort_desc"
      :footer-props="{ 'items-per-page-options': items_per_page }"
      :options.sync="options"
      :server-items-length="total"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.keyword`]="{ item }">
        <div class="domain-list">
          <a v-if="item.keyword" :href="googleLink(item.keyword)" target="_blank" class="col-keyword">{{
            item.keyword
          }}</a>
        </div>
      </template>
      <template v-slot:[`item.comp_urls`]="{ item }">
        <div class="domain-list">
          <a
            v-for="(url, index) in item.comp_urls"
            :key="index"
            :href="externalLink(url)"
            target="_blank"
            class="url-domain"
            >{{ url }}</a
          >
        </div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <a class="pr-2 text--darken-3 text--secondary" @click="goToAction(item)">
          {{ formatDate(item.created_at) }}
        </a>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="cont-actions">
          <v-btn
            v-if="item.status === 'completed'"
            color="primary"
            @click="goToPath('/serp-analysis/report/' + item.id)"
            outlined
          >
            Report
          </v-btn>
          <v-btn
            v-else-if="item.status === 'failed'"
            color="error"
            @click="goToPath('/serp-analysis/await/' + item.id)"
            outlined
          >
            Failed
          </v-btn>
          <v-btn v-else color="warning" @click="goToPath('/serp-analysis/await/' + item.id)" outlined> Creating </v-btn>
          <v-icon color="error" @click="showDialog(item)"> mdi-delete </v-icon>
        </div>
      </template>
    </v-data-table>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <dialog-delete-report
          :show.sync="dialog_delete.show"
          :item="dialog_delete.item"
          title="Are you sure to delete this report?"
          description="This process is irreversible, and can no longer be recovered, would you like to continue?"
          :delete="deleteReport"
        ></dialog-delete-report>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
//icons
import { mdiDelete } from '@mdi/js'
//components
import DialogDeleteReport from '@/components/dialog/DialogDelete.vue'

export default {
  components: {
    mdiDelete,
    DialogDeleteReport,
  },
  data() {
    return {
      loading: false,
      items: [],
      total: 0,
      headers: [
        { text: 'ID:', value: 'id', sortable: true },
        { text: 'Report Name', value: 'report_name', sortable: true },
        { text: 'Keyword', value: 'keyword', sortable: true },
        { text: 'Compare Domain', value: 'comp_urls', sortable: true },
        { text: 'Created At', value: 'created_at', sortable: true },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items_per_page: [5, 10, 25, 50, 100],
      sort_desc: true,
      options: {},
      search: {
        search: '',
      },
      dialog_delete: {
        item: {},
        show: false,
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getReportList()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getReportList()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    ...mapActions({
      getReports: 'serp_analysis/getReports',
      delete: 'serp_analysis/delete',
    }),
    goToPath(path) {
      this.$router.push({ path })
    },
    goTo(route) {
      this.$router.push({ name: route })
    },
    externalLink(url) {
      if (url.includes('https://') || url.includes('http://')) {
        return url
      } else {
        return 'https://' + url
      }
    },
    async getReportList() {
      this.loading = true
      await this.getReports(
        new URLSearchParams({
          ...this.options,
          ...this.search,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.items = resp.data.items
            this.total = resp.data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async deleteReport() {
      this.loading = true
      this.dialog_delete.show = false

      await this.delete(this.dialog_delete.item.id)
        .then(resp => {
          this.dialog_delete.item = null
          this.getReportList()
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatDate(date) {
      return moment(date).fromNow()
    },
    clearSearch() {
      this.search.search = ''
    },
    async showDialog(item) {
      this.dialog_delete.show = true
      this.dialog_delete.item = item
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
  },
}
</script>

<style scoped>
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 200px !important;
  word-break: break-all;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.v-data-table >>> td {
  min-width: 120px;
}
.col-keyword {
  max-width: 200px !important;
  word-break: break-word;
}
.url-domain {
  max-width: 300px !important;
  word-break: break-all !important;
}
.domain-list {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .col-keyword {
    min-width: 200px;
  }
  .url-domain {
    min-width: 300px;
  }
}

@media (max-width: 767px) {
  .header-actions {
    justify-content: center;
    align-items: center;
  }
  .header-top {
    justify-content: center;
    align-items: center;
  }
  .view-title {
    text-align: center;
  }
}
</style>
